import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Banner from "../components/Banner"
import ProductsList from "./products-list"
import "./styles/offerings.scss"

const ProductsTemplate = ({ data }) => {
  const { markdownRemark: markup } = data
  const { banner } = markup.frontmatter

  return (
    <Layout>
      <section className="ProductsTemplate">
        <Banner data={banner} />

        <ProductsList />
      </section>
    </Layout>
  )
}

export const ProductsTemplateQueryByID = graphql`
  query ProductsTemplateQueryByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        name
        banner {
          heading
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`

export default ProductsTemplate
